import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Pagination } from "swiper";
import classes from "./HeroSection.module.css";

import Button from "../../../components/Button/Button";

const HeroSection = (props) => {
    const pagination = {
        clickable: true,
        bulletActiveClass: classes.PaginationButtonActive,
        bulletClass: classes.PaginationButton,
        renderBullet: (index, className) => {
            return `<span class="${className} ${classes.PaginationButton}"></span>`;
        },
    };

    return (
        <>
            <Swiper
                pagination={pagination}
                className={classes.Slider}
                loop={true}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false
                }}
                modules={[Keyboard, Autoplay, Pagination]}
            >
                <SwiperSlide>
                    <div className={classes.SliderImageWrapper}>
                        <img className={classes.SliderImage} src={"/assets/images/home-sliders/home-slider-image-01.png"} alt="Slider 01" />
                        <div className={classes.ImageContent}>
                            <img className={classes.LogoImage} src={"/assets/images/logo/logo-white.svg"} alt="Logo" />
                            <h2>Welcome to Al Shsaze</h2>
                            <p>We specialise in importing and exporting a wide range of plastic products, including but not limited to plastic raw materials, plastic packaging, plastic end products, and plastic industrial items. Our team of experts has years of experience in the plastic industry, allowing us to provide our customers with the best quality products at competitive prices. We work closely with our world-renowned suppliers to ensure that all of our products meet international quality standards.</p>
                            <Button redirectTo="/about-us">Know more</Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.SliderImageWrapper}>
                        <img className={classes.SliderImage} src={"/assets/images/home-sliders/home-slider-image-02.webp"} alt="Slider 02" />
                        <div className={classes.ImageContent}>
                            <h2>PET</h2>
                            <p>Al-Shsaze has been serving the Global PET Industry since 2017. Through our commitment to provide our customers and suppliers with the best services, Al-Shsaze has grown to be a preferred PET provider. Today, we partner with major petrochemical and polymer producers to market and distribute their portfolio of PET to customers worldwide through our footprint across developed and emerging geographies.</p>
                            <Button redirectTo="/products">Know more</Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.SliderImageWrapper}>
                        <img className={classes.SliderImage} src={"/assets/images/home-sliders/home-slider-image-03.webp"} alt="Slider 03" />
                        <div className={classes.ImageContent}>
                            <h2>POLYETHYLENE</h2>
                            <p>Al-Shsaze has established itself as a leading provider of high-density polyethylene (HDPE) through its commitment to delivering exceptional services to its customers and suppliers. We work closely with top petrochemical and polymer manufacturers to distribute their range of HDPE globally, utilizing our extensive presence in both developed and developing regions.</p>
                            <Button redirectTo="/products">Know more</Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classes.SliderImageWrapper}>
                        <img className={classes.SliderImage} src={"/assets/images/home-sliders/home-slider-image-04.webp"} alt="Slider 04" />
                        <div className={classes.ImageContent}>
                            <h2>POLYPROPYLENE</h2>
                            <p>At Al-Shsaze, our dedication to delivering top-notch services has made us a sought-after supplier of Polypropylene versatile thermoplastic polymer. Through partnerships with leading petrochemical and polymer producers, we have expanded our reach and now distribute their wide range of PP products to customers globally, both in established and emerging markets.</p>
                            <Button redirectTo="/products">Know more</Button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
export default HeroSection;