import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getCategoryById, getOtherCategories, getProductsByCategory, getProductsCount } from "../../service/ProductService.js";
import classes from "./Product.module.css";

const Product = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedCategoryConfig, setSelectedCategoryConfig] = useState({
        initatedBy: "",
        categoryId: location.state?.selectedCategoryConfig?.categoryId ? location.state.selectedCategoryConfig?.categoryId : 1,
        firstSubCategoryId: location.state?.selectedCategoryConfig?.firstSubCategoryId ? location.state.selectedCategoryConfig?.firstSubCategoryId : 1,
        secondSubCategoryId: null
    });

    useEffect(() => {
        let updatedConfig = window.history.state?.usr?.selectedCategoryConfig ? window.history.state?.usr?.selectedCategoryConfig : selectedCategoryConfig;
        if (updatedConfig !== selectedCategoryConfig) {
            window.history.replaceState({}, "selectedCategoryConfig")
            setSelectedCategoryConfig(updatedConfig);
        }
    }, [location.state, selectedCategoryConfig]);


    const updateCategoryConfig = (config) => {
        setSelectedCategoryConfig({...config, initatedBy: "product"})
    }
    
    
    const selectedCategory = getCategoryById(selectedCategoryConfig.categoryId);

    const chatNow = (event) => {
        event.stopPropagation();
        window.open("https://api.whatsapp.com/send?phone=8878582384", "_blank");
    }

    return (
        <div className={classes.ProductsPageWrapper}>
            <h1 className={classes.PageHeader}>Our Products</h1>
            <h2 className={classes.PageSubHeader}>{ selectedCategory.name }</h2>
            <div className={classes.TabsWrapper}>
                <Tabs className={classes.Tabs} value={selectedCategoryConfig.firstSubCategoryId - 1} 
                    onChange={(event, newValue) => updateCategoryConfig({
                        ...selectedCategoryConfig,
                        firstSubCategoryId: newValue - 1
                    })} aria-label="basic tabs example"
                    TabIndicatorProps={{ sx: {backgroundColor: 'transparent'}}}>
                    {selectedCategory.subCategories.map((sc, index) => 
                        <Tab label={`${sc.name}(${getProductsCount(selectedCategory.id, sc.id)})`}
                            key={`${sc.id} ${sc.name}`}
                            id={`simple-tab`}
                            onClick={() => updateCategoryConfig({ ...selectedCategoryConfig, firstSubCategoryId: sc.id })}
                            aria-controls={`simple-tabpanel`} />)}

                </Tabs>
                <div className={classes.ProductAndCategoryWrapper}>
                    <div className={classes.ProductsSection}>
                        {selectedCategory.subCategories.map((subCategory, index) => <>
                            <div key={`${subCategory.id}`} className={classes.Products}
                                role="tabpanel" hidden={selectedCategoryConfig.firstSubCategoryId !== index+1}
                                id={`simple-tab`}
                                aria-controls={`simple-tabpanel`}>
                                <div className={classes.ProductTags}>
                                    {subCategory?.subCategories.map((tag, index) => 
                                        <div key={`${tag.id} ${tag.name}`}   
                                            className={selectedCategoryConfig.secondSubCategoryId === tag.id ? classes.Selected : ''}
                                            onClick={() => updateCategoryConfig({ ...selectedCategoryConfig, secondSubCategoryId: tag.id})}>
                                            {tag.name}   
                                        </div>)}                                   
                                </div>
                                {selectedCategoryConfig.firstSubCategoryId === index+1 &&
                                    getProductsByCategory(selectedCategoryConfig.categoryId, 
                                        selectedCategoryConfig.firstSubCategoryId, 
                                        selectedCategoryConfig.secondSubCategoryId).map((product, index) => {
                                            return (<div key={`${product.id} ${product.fullName}`} onClick={() => navigate(`/products/${product.id}`)} className={classes.ProductListingCard}>
                                                <div className={classes.ProductCardImage}><img onError={(event) => {event.target.src = '/assets/images/our-products/default-image.png'}} src={`/assets/images/our-products/${product.listingImageId}/listing-image.${product.listingImageFormat}`} alt={product.fullName} /></div>
                                                <div className={classes.ProductCardContent} >
                                                    <div className={classes.ProductText}>
                                                        <span>{subCategory.name}</span>
                                                        <span>{product.shortName}</span>
                                                    </div>
                                                    <div className={classes.ActionBtnWrapper} onClick={(event) => chatNow(event)}>
                                                        <div className={classes.ActionBtn}>
                                                            <img src={"/assets/images/our-products/icons/whatsapp.svg"} alt="whatsapp" /> <span>Chat Now</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                    })}
                            </div>
                        </>)}
                    </div>
                    <div className={classes.CategorySection}>
                        <p className={classes.Header}>Other Categories</p>
                        <div className={classes.Category}>
                        {
                            getOtherCategories(selectedCategory.id).map((category, index) => {
                                return (<div key={`${category.id}`} className={classes.OtherCategory}>
                                        <div className={classes.SubHeader}>
                                            <p>{category.name}</p>
                                            <p>
                                                <span>0{category?.subCategories.length}</span> 
                                                <span>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </p>
                                        </div>
                                        <div className={classes.SubCategories}>
                                        {category?.subCategories?.map((subcategory, index) => {
                                                    return <p 
                                                        onClick={() => updateCategoryConfig({ 
                                                            ...selectedCategoryConfig,
                                                            categoryId: category.id,
                                                            firstSubCategoryId: subcategory.id,
                                                            secondSubCategoryId: null
                                                         })} 
                                                        key={`${subcategory.name}`}>
                                                            {subcategory.name}
                                                        </p>;
                                                }) }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;