import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Navigation/Logo/Logo";

import classes from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.LeftSection}>
        <Logo wrapperCssClass={classes.LogoWrapper} />
        <p className={classes.CompanyInfo}>
          Al Shsaze’s objective is to deliver complete customer satisfaction by addressing the main needs of the physical commodity industry, by continually providing an added value to the overall supply chain.
        </p>
        <p className={classes.CopyrightInfo}>
          Copyright © 2023. All rights reserved
          Website by <a className={classes.ShiftDesignLink} href="https://theshiftdesigns.com/#/" target={"_blank"} rel="noreferrer">Shift Design Studio</a>
        </p>
      </div>

      <div className={classes.RightSection}>
        <p className={classes.UsefulLinksHeader}>Useful Links</p>
        <div className={classes.PageLink}>
          <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
          <NavLink to={"/terms-and-conditions"}>Terms & Conditions</NavLink>
          <NavLink to={"/cookie-policy"}>Cookie Policy</NavLink>
          <NavLink to={"/about-us"}>About Us</NavLink>
          <NavLink to={"/contact-us"}>Contact Us</NavLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;