import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./SliderSection.module.css";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const SliderSection = (props) => {

    const [swiper, setSwiper] = useState(null);
    const slideData = props?.data;

    return (
        <div className={classes.SliderSection}>
            <h2>{props.headerName}</h2>
            <div className={classes.PaginationButtonGroup}>
                <div onClick={() => swiper.slidePrev()}><FontAwesomeIcon icon={faAngleLeft}/></div>
                <div onClick={() => swiper.slideNext()}><FontAwesomeIcon icon={faAngleRight}/></div>
            </div>
            <div>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={120}
                    loop={true}
                    keyboard={{
                        enabled: true,
                    }}
                    onSwiper={(s) => setSwiper(s)}
                    {...props?.swiperConfig}
                >
                    {slideData?.map((data, index) => {
                        return (
                            <SwiperSlide key={data.name}>
                                <div>
                                    <img className={clsx(classes.SliderImage, props.imageCssClass)} src={`/assets/images/${data.imagePath}`} alt={data.name} />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}
export default SliderSection;