import React from "react";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import classes from "./About.module.css";

const About = (props) => {

    const visionCards = [
        {
            title: "Leadership",
            description: `Inspiring others to engage with our vision. Inspiring others to engage with our vision.|||`
        },
        {
            title: "Innovation",
            description: `Thinking outside the box to add value throughout the supply chain.`
        },
        {
            title: "Perseverance",
            description: `Commitment to not give up when faced with adversity or challenge.`
        },
        {
            title: "Passion",
            description: `Expressing enthusiasm in our daily work. Expressing enthusiasm in our daily work.|||`
        },
        {
            title: "Strong work ethic",
            description: `Possessing a desire to work hard and willingness to put in the extra effort to.`
        },
        {
            title: "Teamwork",
            description: `Demonstrating commitment to one another as well as those with whom.`
        }
    ];


    return (
        <div className={classes.About}>
            <SectionBanner 
                wrapperCssClass={classes.HeroBanner}
                bgImagePath={"/assets/images/about-us/hero-image.png"}
                tag={"DISCOVER OUR WAY"}
                header={"About us"}
                paragraph={"Our focus is on the import and export of a diverse range of plastic products, encompassing plastic raw materials, plastic packaging, plastic end products, and plastic industrial items. Leveraging the extensive expertise of our skilled professionals in the plastic industry, we are able to offer our clients top-notch products at competitive rates. We maintain close collaboration with our globally recognized suppliers to guarantee that all our products comply with the highest global quality benchmarks."}
            />

            <div className={classes.Stats}>
                <img src={"/assets/images/about-us/stats.jpg"} alt="statistics" />
            </div>

            <div className={classes.History}>
                <h2>History</h2>
                <img src={"/assets/images/about-us/history.jpg"} alt="history" />
            </div>

            <div className={classes.OurVision}>
                <h2>Our Vision</h2>
                <p>We believe the following values set us apart from our competition:</p>
                <div className={classes.VisionCards}>
                    {visionCards.map(visionCard => {
                        return (
                            <div key={visionCard.title} className={classes.VisionCard}>
                                <p>{visionCard.title}</p>
                                <p>{visionCard.description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default About;