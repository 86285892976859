import React, { useRef } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ProductDetail.module.css"
import { useParams, useNavigate } from 'react-router-dom';
import { getProductById, getOtherCategories, getCategoryById, getProductsByCategory } from "../../../service/ProductService.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import clsx from "clsx";


const ProductDetail = (props) => {
    const navigate = useNavigate();
    const pdfDownloadContentRef = useRef();

    const { id } = useParams();
    const product = getProductById(id);
    const productCategory = getCategoryById(product.categoryId);
    const productFirstSubCategory = productCategory?.subCategories?.find(sub => sub.id === product.firstSubCategoryId);


    const chatNow = (event) => {
        window.open("https://api.whatsapp.com/send?phone=8878582384", "_blank");
    }

    const onOtherProductClick = (id) => {
        navigate(`/products/${id}`);
    }

    const onOtherCategoryClick = (categoryId, firstSubCategoryId) => {
        navigate(`/products`, { state: {
            selectedCategoryConfig: {
                categoryId: categoryId,
                firstSubCategoryId: firstSubCategoryId,
                secondSubCategoryId: null
            }
        } });
    }

    const onPdfPreview = (product) => {
        let content = pdfDownloadContentRef.current;
        const productImage = content.querySelector('#product-image');
        const originalProductImage = productImage.src;
        productImage.src = '/assets/images/logo/logo.svg';
        productImage.classList.add(classes.ProductImagePrint);

        html2canvas(content, {
            ignoreElements: (element) => element.id === 'chat-now-btn' || element.id === 'tds',

        }).then((canvas) => {
            let PDF = new jsPDF('p', 'px', 'a4');
            const pageWidth = PDF.internal.pageSize.getWidth() - 2;
            const pageHeight = PDF.internal.pageSize.getHeight();
            const FILEURI = canvas.toDataURL('image/jpeg');
            let position = 0;
            PDF.addImage(FILEURI, 'JPEG', 0, position, pageWidth, pageHeight);
            window.open(PDF.output('bloburl'), '_blank');
        });

        productImage.src = originalProductImage;
        productImage.classList.remove(classes.ProductImagePrint);
    }

    const onPdfDownload = (product) => {
        let content = pdfDownloadContentRef.current;

        const productImage = content.querySelector('#product-image');
        const originalProductImage = productImage.src;
        productImage.src = '/assets/images/logo/logo.svg';
        productImage.classList.add(classes.ProductImagePrint);

        html2canvas(content, {
            ignoreElements: (element) => element.id === 'chat-now-btn' || element.id === 'tds',
        }).then((canvas) => {
            let PDF = new jsPDF('p', 'px', 'a4');
            const pageWidth = PDF.internal.pageSize.getWidth()-2;
            const pageHeight = PDF.internal.pageSize.getHeight();
            const FILEURI = canvas.toDataURL('image/jpeg');
            let position = 0;
            PDF.addImage(FILEURI, 'JPEG', 0, position, pageWidth, pageHeight);
            PDF.save(`tds-${product?.id}.pdf`);
        });

        productImage.src = originalProductImage;
        productImage.classList.remove(classes.ProductImagePrint);
    }

    return (
        <div className={classes.ProductDetailWrapper}>
            {
                product ? <>
                    <div className={classes.Content}>
                        <div className={classes.ProductDetail} ref={pdfDownloadContentRef}>
                            <div className={classes.TopInfo}>
                                <div className={classes.ProductImageWrapper}>
                                    <img id="product-image" onError={(event) => { event.target.src = "/assets/images/our-products/default-image.png" }} src={`/assets/images/our-products/${product.listingImageId}/listing-image.${product.listingImageFormat}`} alt="product" />
                                </div>
                                <div className={classes.Details}>
                                    <p className={classes.Header}>{product.shortName}</p>
                                    <p className={classes.SubHeader} title={product.fullName}>{product.fullName}</p>

                                    <p className={classes.Tag}>
                                        <span className={classes.Key}>Chemical Formula:</span>
                                        <span className={classes.Value} title={product.chemicalFormula}>{product.chemicalFormula}</span>
                                    </p>
                                    <p className={classes.Tag}>
                                        <span className={classes.Key}>Grade:</span>
                                        <span className={classes.Value} title={product.grade}>{product.grade}</span>
                                    </p>
                                    <p className={classes.Tag}>
                                        <span className={classes.Key}>Packaging:</span>
                                        <span className={classes.Value} title={product.packaging}>{product.packaging}</span>
                                    </p>
                                    <p className={classes.Tag}>
                                        <span className={classes.Key}>Company:</span>
                                        <span className={classes.Value} title={product.company}>{product.company}</span>
                                    </p>

                                    <div id="chat-now-btn" className={classes.ChatNowBtnWrapper} onClick={(event) => chatNow(event)}>
                                        <div className={classes.ChatNowBtn}>
                                            <img src={"/assets/images/our-products/icons/whatsapp.svg"} alt="whatsapp" /> <span onClick={() => chatNow()}>Chat Now</span>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className={classes.BottomInfo}>
                                <div className={classes.Description}>
                                    <p className={classes.Header}>Product Analysis</p>
                                    <p className={classes.Text}>{product?.description}</p>
                                </div>
                                <div className={classes.InfoTable}>
                                    <table>
                                        <tr>
                                            <th>Parameter</th>
                                            <th>Unit</th>
                                            <th>Value</th>
                                            <th>Test Method</th>
                                        </tr>
                                        {product?.parameters?.map(param => (<tr key={param.name}>
                                            <td>{param.name}</td>
                                            <td>{param.unit}</td>
                                            <td>{param.value}</td>
                                            <td>{param.testMethod}</td>
                                        </tr>))}
                                    </table>
                                </div>
                                <div id="tds" className={classes.TDS}>
                                    <p className={classes.Header}>Technical Data Sheet (TDS)</p>
                                    <div className={classes.DocumentWrapper}>
                                        <p className={classes.Text}>{product.fullName} ({product.grade})</p>
                                        <div className={classes.Buttons}>
                                            <p className={clsx(classes.Button, classes.PreviewButton)} onClick={() => onPdfPreview(product)}>Preview</p>
                                            <p className={classes.Button} onClick={() => onPdfDownload(product)}>Download</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                        <div className={classes.Others}>
                            <div className={classes.CategorySection}>
                                <p className={classes.Header}>Other Products in {productFirstSubCategory?.name}</p>
                                <div className={classes.OtherProducts}>
                                {
                                    getProductsByCategory(product.categoryId, product.firstSubCategoryId, null)
                                            .filter(p => p.id !== product.id)
                                            .map(product => <p onClick={() => onOtherProductClick(product.id)} key={product.id}>{ product.shortName }</p>)
                                }
                                </div>
                            </div>
                            <div className={classes.CategorySection}>
                                <p className={classes.Header}>Other Categories</p>
                                <div className={classes.Category}>
                                {
                                    getOtherCategories(product.categoryId).map(category => {
                                        return <>
                                            <div key={category.id} className={classes.OtherCategory}>
                                                <div className={classes.SubHeader}>
                                                    <p>{category.name}</p>
                                                    <p>
                                                        <span>0{category?.subCategories.length}</span>
                                                        <span>
                                                            <FontAwesomeIcon icon={faAngleDown} />
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className={classes.SubCategories}>
                                                    {category?.subCategories?.map(subcategory => {
                                                        return <p
                                                            onClick={() => onOtherCategoryClick(category.id, subcategory.id)}
                                                            key={subcategory.id}>
                                                            {subcategory.name}
                                                        </p>;
                                                    })}
                                                </div>
                                            </div>
                                        </>;
                                    })
                                }
                                </div>
                            </div>

                        </div>
                    </div>
                </> 
                : 
                <div>
                    Product Not Found
                </div>
            }
        </div>);
}
export default ProductDetail;