import React from "react";
import classes from "./TermsAndCondition.module.css";

const TermsAndCondition = (props) => {
    return (
        <div className={classes.Page}>
            <h2 className={classes.PageHeader}>Terms and Conditions</h2>
            <div className={classes.SectionCard}>
                <p className={classes.TextPara}>By accessing this website ('the Website'), you acknowledge and agree to accept the following Terms of Use, which constitutes an agreement between you and Alshsaze ('Alshsaze', 'we', 'us', or 'our').</p>
                <p className={classes.Header}>DATA PRIVACY</p>
                <p className={classes.TextPara}>By using our website, you acknowledge and agree to our Privacy Policy.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>CONTENTS OF OUR WEBSITE</p>
                <p className={classes.TextPara}>All material, content, and information (Content) on the Site shall be and remain the property of Alshaze or its respective owners. Alshaze owns or has rights to all wallpaper, icons, characters, artwork, images, graphics, music, text, software and other material of the Site and all code and scripts in any format used to implement the Site. You agree not to copy, reproduce, sell, distribute, mirror, republish, upload, download, transmit, display for redistribution or create derivative works of the Content or the Site, in whole or in part, by any means. All Content on this Site is the exclusive property of Alshaze and is protected by U.S. and international copyright, trademark and other proprietary and industrial property laws.</p>
                <p className={classes.TextPara}>Alshaze Trademarks means all names, marks, brands, logos, designs, trade dress, slogans, graphics and other designations Alshaze uses in connection with its products and services. You may not remove or alter any Alshaze Trademarks, or use the Alshaze Trademarks, without Alshazes prior written consent. You acknowledge Alshazes rights in Alshaze Trademarks and agree that any use of Alshaze Trademarks by You shall inure to Alshazes sole benefit.</p>
                <p className={classes.TextPara}>You may not transmit any virus, worm, malware, or other program or harmful device through this Website. Alshaze may at its sole discretion monitor this Website for any unauthorized or objectionable use or transmission and take actions in response, without notice to you. Alshaze may revoke your right to use all or any portion of the Website at its sole discretion. Alshaze may investigate any suspected violation of these Terms, and may report any activity relating to this Website to regulators, law enforcement officials or any other appropriate authority.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>INTERNATIONAL USE</p>
                <p className={classes.TextPara}>The Website is operated and controlled in the United States, and the Content provided in or accessible through the Website is directed to residents of the United States unless otherwise specified. Alshaze makes no claim that the Content or the Website are appropriate for use outside the United States. Alshaze makes no representations that the transactions, products or services discussed on or accessible through the Website are available or appropriate for sale or use in all jurisdictions or by all users. Those who access the Website from outside the United States do so on their own initiative and are responsible for compliance with local laws, rules and regulations.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>LINKED WEBSITES</p>
                <p className={classes.TextPara}>Alshaze does not review or monitor any websites linked from or to the Website and is not responsible for the content of any such websites, or any products or services contained on or accessible through such websites, their terms of use or privacy policies. You are advised to review the terms of use and privacy policies of any website that you visit. Alshaze disclaims responsibility for the privacy policies and customer information practices of websites linked from the Website.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>LIMITATION OF LIABILITY AND DISCLAIMERS</p>
                <p className={classes.TextPara}>Alshaze disclaims all warranties and representations, express or implied, regarding information contained in or accessed from the Site, including, but not limited to, all express and implied warranties, such as warranty of title, merchantability, non-infringement and fitness for a particular purpose. Alshaze does not warrant that the Site is free of defects or harmful components and in provided on an as is and where is basis. Alshaze does not warrant that the Site will be uninterrupted or error-free or that errors will be corrected. Alshaze does not assume any liability for the accuracy or completeness of the information disclosed or accessed through the Site. It is your responsibility to verify any information contained on the Site before relying upon it.</p>
                <p className={classes.TextPara}>In no event shall Alshaze, ITS AFFILIATES OR ANY OF ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, CONTENT PROVIDERS OR SERVICE PROVIDERS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, LOSSES OR ANY OTHER DAMAGES OF WHATEVER KIND (including, but not limited to, loss of use, lost profits, loss of data or information, or loss of business goodwill or opportunity, whether tangible or intangible) RESULTING FROM WHATEVER CAUSE, WHETHER IN AN ACTION UNDER THEORIES OF CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN ANY WAY RELATING TO THE USE OR PERFORMANCE OF THE INFORMATION ON, OR ACCESSED THROUGH, THE WEBSITE.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>GOVERNING LAW AND FORUM</p>
                <p className={classes.TextPara}>The laws of the State of Texas govern these Terms of Use. Any dispute relating to the Terms of Use, or your use of the Website shall be resolved solely in the state court located in Harris County, Texas. You agree to waive trial by jury in any such action.</p>
            </div>

        </div>
    );
}
export default TermsAndCondition;