import clsx from "clsx";
import React from "react";
import { useNavigate } from 'react-router';

import classes from "./Logo.module.css";

const Logo = (props) => {
  const navigate = useNavigate();

  const logoClicked = () => navigate("/");

  return (
    <div className={clsx(classes.LogoImage, props.wrapperCssClass)} onClick={logoClicked}>
      <img src={"/assets/images/logo/logo.svg"} alt="Logo" />
    </div>
  );
};
export default Logo;
