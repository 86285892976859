import React, { Component } from "react";
import NavigationTabs from "../NavigationTabs/NavigationTabs";

import classes from "./Header.module.css";

class Header extends Component {
  render() {
    return (
      <header className={classes.Header}>
        <NavigationTabs/>
      </header>
    );
  }
}

export default Header;
