import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./Button.module.css";

const Button = (props) => {
    return (<NavLink className={clsx(classes.Button, props.cssClassName)} to={props.redirectTo}>
                {props.children}
            </NavLink>);
}
export default Button;