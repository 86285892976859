import React from "react";
import { Autoplay, Keyboard } from "swiper";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import SectionTag from "../../components/SectionTag/SectionTag";
import HeroSection from "./HeroSection/HeroSection";
import classes from "./Home.module.css";
import SliderSection from "./SliderSection/SliderSection";

const Home = (props) => {

    const products = Array.from({ length: 20 }, (_, index) => {
        let productNo = (index % 5) + 1
        return {
            name: `product-0${index}`,
            imagePath: `our-products/home-page-products/product-0${productNo}.png`
        }
    });

    const suppliers = Array.from({ length: 20 }, (_, index) => {
        return {
            name: `supplier-${index}`,
            imagePath: `our-suppliers/supplier-0${(index%9)+1}.png`
        }
    });

    const locations = ['Dubai (HQ)', 'India', 'Thailand', 'Taiwan', 'Veitnam', 'China', 'Bangladesh',
        'Oman', 'Saudi Arabia', 'United Kingdom', 'United States of America'];


    return (
        <div className={classes.Home}>
            <HeroSection />

            <div className={classes.SuppliersSlider}>
                <SliderSection
                    swiperConfig={{
                        speed: 5000,
                        spaceBetween: 0,
                        loop: true,
                        centeredSlides: true,
                        slidesPerView: 4,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                        },
                        breakpoints: {
                            // when window width is >= 320px
                            0: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            910: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                        },
                        modules: [Autoplay]
                    }}
                    imageCssClass={classes.SupllierSlideImage}
                    headerName={"Our Suppliers"}
                    data={suppliers} />
            </div>
            
            <div className={classes.ProductsSlider}>
                <SliderSection 
                    swiperConfig={{
                        slidesPerView: 5,
                        autoplay: {
                            delay: 2500,
                            disableOnInteraction: false
                        },
                        breakpoints: {
                            // when window width is >= 320px
                            0: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            910: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1400: {
                                slidesPerView: 5,
                            },
                        },
                        modules: [Keyboard, Autoplay]
                    }}
                    headerName={"Our Products"} data={products} 
                />
            </div>

            <SectionBanner
                bgImagePath={"/assets/images/career-section/bg-image.png"}
                tag={"CAREERS"}
                header={"Discover what it's like to be part of the Al Shsaze team"}
                paragraph={"Fundamental to the growth of the business is our ability to attract, hire, and retain the best talent. Our people are among the most creative, ambitious and diverse in the industry, and we constantly strive to build on our exceptional talent."}
                buttonText={"Get in touch"}
                buttonLink={"/contact-us"}
            />

            <div className={classes.LocationSection}>
                <SectionTag color={"black"}>OUR LOCATIONS</SectionTag>
                <h2 className={classes.TextHead}>Locations</h2>
                <h2 className={classes.SubHead}>We serve in 12 Countries with Headquaters in Dubai, UAE</h2>
                <div className={classes.LocationTags}>
                    {locations?.map((location) => (<span key={location}>{location}</span>))}
                </div>
                <div className={classes.LocationImage}>
                    <img src={"/assets/images/location-section/locations.svg"} alt="locations available" />
                </div>
            </div>

            <SectionBanner
                wrapperCssClass={classes.MissionSection}
                bgImagePath={"/assets/images/mission-section/bg-image.png"}
                tag={"SUSTAINABLE DEVELOPMENT"}
                header={"Our Mission"}
                paragraph={"Al Shsaze is committed to sustainability in the plastic industry. We promote eco-friendly practices and offer biodegradable and recycled plastic products. We works with suppliers to ensure sustainability standards are met and aims to reduce the environmental impact of their operations."}
            />

            <SectionBanner
                wrapperCssClass={classes.ContactSection}
                bgImagePath={"/assets/images/contact-section/bg-image.png"}
                tag={"LET’S CONNECT"}
                header={"Contact us"}
                paragraph={"See how you can connect with us"}
                buttonText={"Get in touch"}
                buttonLink={"/contact-us"}
            />

        </div>
    );
}
export default Home;