import React from "react";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = (props) => {
    return (
        <div className={classes.Page}>
            <h2 className={classes.PageHeader}>Privacy Policy</h2>
            <div className={classes.SectionCard}>
                <p className={classes.TextPara}>This Privacy Policy describes how your personal information is collected, used, and shared when you visit www.alshsaze.com (the "Site").</p>
                <p className={classes.Header}>PERSONAL INFORMATION WE COLLECT</p>
                <p className={classes.TextPara}>We may collect from time to time information about you from which you can be identified - including your name, address, telephone number, email address, billing and account information, credit or debit card information, and other information incidental to providing goods or services to you as well as information that can be reasonably linked to information that identifies you (Personal Information).</p>
                <p className={classes.TextPara}>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as Device Information.</p>
                <p className={classes.TextPara}>We collect Device Information using the following technologies</p>
                <div className={classes.TextPara}>
                    <p>1. Cookies are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</p>
                    <p>2. Log files track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
                    <p>3. Web beacons, tags, and pixels are electronic files used to record information about how you browse the Site.</p>
                </div>
                <p className={classes.TextPara}>We use cookies for the following purposes:</p>
                <div className={classes.TextPara}>
                    <p>1. Authentication and navigation - we use cookies to identify you when you visit our Site and as you navigate our Site;</p>
                    <p>2. Personalization - we use cookies to store information about your preferences and to personalize our Site for you;</p>
                    <p>3. Security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our Site and services generally; and</p>
                    <p>4. Analysis - we use cookies to help us to analyze the use and performance of our Site and services.</p>
                </div>
                <p className={classes.TextPara}>Cookies are used by our service providers. We use Google Analytics to analyze the use of our Site. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our Site is used to create reports about the use of our Site. Google's privacy policy is available at: https://www.google.com/policies/privacy/.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>MANAGING COOKIES</p>
                <p className={classes.TextPara}>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
                <div className={classes.TextPara}>
                    <p>1. https://support.google.com/chrome/answer/95647?hl=en (Chrome);</p>
                    <p>2. https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);</p>
                    <p>3. http://www.opera.com/help/tutorials/security/cookies/ (Opera);</p>
                    <p>4. https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);</p>
                    <p>5. https://support.apple.com/kb/PH21411(Safari); and</p>
                    <p>6. https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</p>
                </div>
                <p className={classes.TextPara}>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                <p className={classes.TextPara}>If you block cookies, you will not be able to use all the features on our Site.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
                <p className={classes.TextPara}>Where required under applicable law, we only process your Personal Information on the basis of a lawful justification for processing. In those cases, the processing of your Personal Information will be justified on the basis that it is necessary for the performance of a contract with you or in order to take steps at your request prior to entering into a contract, or as necessary for the purposes of our legitimate interests of providing for sale various products to users of the Site and operating, protecting and improving our business.</p>
                <p className={classes.TextPara}>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                <p className={classes.TextPara}>We may keep your Personal Information for as long as is necessary for our business purposes, and in particular to protect ourselves in the event of a legal claim. After this period, your data will be deleted or, in some instances, anonymized.</p>
                <p className={classes.TextPara}>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
                <p className={classes.TextPara}>Your data will in part also be processed in countries outside the European Union (EU) or the European Economic Area (EEA), which may have a lower data protection level than European countries. In such cases, we will ensure that a sufficient level of protection is provided for your data, e.g., by concluding specific agreements with our contractual partners (copy available on request), or we will ask for your explicit consent to such processing.</p>
                <p className={classes.TextPara}>Protecting your personal information</p>
                <p className={classes.TextPara}>We understand the utmost importance of protecting the Personal Information that you share with us. As such, we practice a combination of technical, physical and administrative security controls that are designed to safeguard your information against unauthorized use. Your Personal Information will only be used for business purposes and will only be accessible by a limited number of our employees and third party vendors servicing our computer and web services.</p>
                <p className={classes.TextPara}>At the same time, no data or transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal Information to the best of our ability, we cannot guarantee or warrant the security of information you transmit to our Site.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>SHARING YOUR PERSONAL INFORMATION</p>
                <p className={classes.TextPara}>We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>
                <p className={classes.TextPara}>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>BEHAVIOURAL ADVERTISING</p>
                <p className={classes.TextPara}>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiatives (NAI) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
                <p className={classes.TextPara}>You can opt out of targeted advertising by:</p>
                <div className={classes.TextPara}>
                    <p>FACEBOOK - https://www.facebook.com/settings/?tab=ads</p>
                    <p>GOOGLE - https://www.google.com/settings/ads/anonymous</p>
                    <p>BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</p>
                </div>
                <p className={classes.TextPara}>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliances opt-out portal at: http://optout.aboutads.info/.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>DO NOT TRACK</p>
                <p className={classes.TextPara}>Please note that we do not alter our site's data collection and use practices when we see a Do Not Track signal from your browser.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>YOUR RIGHTS</p>
                <p className={classes.TextPara}>The following rights are generally available to European residents according to applicable data privacy laws:</p>
                <ul className={classes.TextPara}>
                    <li>Right of information about your Personal Information stored by us;</li>
                    <li>Right to request the correction, deletion or restricted processing of your Personal Information;</li>
                    <li>Right to object to a processing for reasons of our own legitimate interest, public interest, or profiling, unless we are able to proof that compelling, warranted reasons superseding your interests, rights and freedom exist, or that such processing is done for purposes of the assertion, exercise or defense of legal claims;</li>
                    <li>Right to data portability;</li>
                    <li>Right to file a complaint with a data protection authority;</li>
                    <li>You may at any time with future effect revoke your consent to the collection, processing and use of your personal information. For further information please refer to the chapters above describing the processing of data based on your consent.</li>
                </ul>
                <p className={classes.TextPara}>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliances opt-out portal at: http://optout.aboutads.info/.</p>
                <p className={classes.TextPara}>If you wish to exercise your rights, please address your request to info@alshsaze.com and include European Data Subject Request in the subject line of the email.</p>
                <p className={classes.TextPara}>Where you have such a right under applicable law, such as in the EU, you may complain to a data protection authority if you think we have processed your Personal Information in a manner which is unlawful or breaches your rights.</p>
                <p className={classes.TextPara}>Nothing in this Privacy Policy shall give any individual any right or rights which that individual does not otherwise have under the General Data Protection Regulation and/or any other applicable data protection laws.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>CHANGES</p>
                <p className={classes.TextPara}>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. You should check this page from time to time to familiarize yourself with any changes to this Privacy Policy.</p>
            </div>

            <div className={classes.SectionCard}>
                <p className={classes.Header}>CONTACT US</p>
                <p className={classes.TextPara}>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@alshsaze.com or by mail using the details provided below:</p>
            </div>
        </div>
    );
}
export default PrivacyPolicy;