import React from "react";
import classes from './SectionTag.module.css';

const SectionTag = (props) => {
    const color = props?.color ? props?.color : 'white';

    return (<div>
        <div style={{ color: color}} className={classes.SectionTag}>
            <div style={{ backgroundColor: color }}></div>
            <div><p>{props.children}</p></div>
        </div>
    </div>);
}
export default SectionTag;