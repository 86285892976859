import clsx from "clsx";
import React from "react";
import Button from "../Button/Button";
import SectionTag from "../SectionTag/SectionTag";

import classes from './SectionBanner.module.css';

const SectionBanner = (props) => {

    const backgroundImage = props.bgImagePath ? (<div className={classes.BackgroundImage}><img src={`${props.bgImagePath}`} alt={props.tag} /></div>) : undefined;
    const sectionTag = props.tag ? (<SectionTag>{props.tag}</SectionTag>) : undefined;
    const header = props.header ? (<h2 className={classes.TextHead}>{props.header}</h2>) : undefined;
    const paragraph = props.paragraph ? (<p className={classes.TextPara}>{props.paragraph}</p>) : undefined;
    const button = props.buttonText ? (<div className={classes.ButtonWrapper}><Button redirectTo={props.buttonLink}>{props.buttonText}</Button></div>) : undefined;

    return (
        <div className={clsx(classes.Section, props.wrapperCssClass)}>
            { backgroundImage }
            { sectionTag }
            { header }
            { paragraph }
            { button }
        </div>
    );
}

export default SectionBanner;