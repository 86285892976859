import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import classes from "./Contact.module.css";
import { Alert, Snackbar } from "@mui/material";
import axios from "axios";

const schema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required").email("Not a valid email format"),
    phoneNumber: yup.string().matches(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "Not a valid phone number").required("Phone number is required"),
    message: yup.string().required("Message is required")
}).required();

const Contact = (props) => {
    const [snackbarstate, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    
    const onSubmit = data => {
        console.log(data)
        axios.post('https://alshsaze.com/api/contact-mail.php', {
            "first_name": data.firstName,
            "last_name": data.lastName,
            "email_id": data.email,
            "phone_number": data.phoneNumber,
            "message": "Test mail"
        }).then((response) => {
            setSnackbarState({ open: true, vertical: "bottom", horizontal: "center" })
        }, (error) => {
            setSnackbarState({ open: true, vertical: "bottom", horizontal: "center" })
        });
    };

    const handleSnackbarClose = (event, reason) => {
        setSnackbarState({ ...snackbarstate, open: false });
    };

    return (
        <div className={classes.Contact}>
            <SectionBanner 
                wrapperCssClass={classes.HeroBanner}
                bgImagePath={"/assets/images/contact-us/hero-image.png"}
                tag={"CONTACT US"}
                header={"Get in touch"}
                paragraph={"Want to get in touch? We’d love to hear from you. Here’s how you can reach us…"}
            />

            <div className={classes.ContactCard}>
                <div className={classes.Card}>
                    <div className="icon-wrapper">
                        <img src="/assets/images/contact-us/telephone-icon.svg" alt="telehphone" />
                    </div>
                    <h2>Contact Office</h2>
                    <p>If you have any questions about our products and services, Just pick up the phone and call us</p>
                    <NavLink className={classes.Button}>+971 55 791 3600</NavLink>
                </div>

                <div className={classes.Card}>
                    <div className="icon-wrapper">
                        <img src="/assets/images/contact-us/mobile-icon.svg" alt="telehphone" />
                    </div>
                    <h2>Contact Export Unit</h2>
                    <p>The Al-Shasaze team will assist you in supplying the required raw materials</p>
                    <NavLink className={classes.Button}>+971 55 791 3600</NavLink>
                </div>
            </div>


            <div className={classes.FormWrapper}>
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div className={classes.EqualySpacedInputs}>
                        <div className={clsx(classes.CustomInputGrp, classes.FirstNameInput)}>
                            <label htmlFor="first-name">First Name</label>
                            <input id="first-name" placeholder="Please enter your first name" {...register("firstName")} />
                            <p>{errors.firstName?.message}</p>
                        </div>

                        <div className={clsx(classes.CustomInputGrp, classes.LastNameInput)}>
                            <label htmlFor="last-name">Last Name</label>
                            <input id="last-name" placeholder="Please enter your last name" {...register("lastName")} />
                            <p>{errors.lastName?.message}</p>
                        </div>

                        <div className={clsx(classes.CustomInputGrp, classes.EmailIdInput)}>
                            <label htmlFor="email-id">Email ID</label>
                            <input id="email-id" placeholder="Please enter your email ID" {...register("email")} />
                            <p>{errors.email?.message}</p>
                        </div>

                        <div className={clsx(classes.CustomInputGrp, classes.PhoneNumberInput)}>
                            <label htmlFor="phone-number">Phone Number</label>
                            <input id="phone-number" placeholder="Please enter your phone number" {...register("phoneNumber")} />
                            <p>{errors.phoneNumber?.message}</p>
                        </div>
                    </div>

                    <div className={clsx(classes.CustomInputGrp, classes.MessageInput)}>
                        <label htmlFor="message">Your Message</label>
                        <input id="message" placeholder="Please enter your message here" {...register("message")} />
                        <p>{errors.message?.message}</p>
                    </div>

                    <button className={classes.SubmitBtn} type="submit">Submit</button>
                </form>
            </div>

            <Snackbar anchorOrigin={{ vertical: snackbarstate.vertical, horizontal: snackbarstate.horizontal }} open={snackbarstate.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Thank you for contacting us. We'll get back to you shortly!
                </Alert>
            </Snackbar>
        </div>
    );
}
export default Contact;