import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Logo/Logo";
import classes from "./NavigationTabs.module.css"
import Button from "../../Button/Button";
import { getAllCategories } from "../../../service/ProductService";

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const NavigationTabs = (props) => {
    let navigationWrapperClasses = [classes.NavigationTabsWrapper];
    
    const [screenType, setScreenType] = useState(getWindowSize()?.innerWidth <= 850 ? "SMALL_SCREEN" : "BIG_SCREEN");

    let [hamburgerMenuCssClasses, setHamburgerMenuCssClasses] = useState([classes.Hamburger]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleWindowResize = () => {
        const windowSize = getWindowSize();
        if (windowSize.innerWidth <= 850) {
            setScreenType("SMALL_SCREEN");
        } else {
            setScreenType("BIG_SCREEN");
        }
    }

    const toggleNavbar = (event, tab) => {
        if (tab?.children?.length > 0) {
            if(screenType === 'BIG_SCREEN') {
                event.preventDefault();
            } else if (screenType === 'SMALL_SCREEN') {
                event.preventDefault();
                return;
            }
        }

        if(!isMenuOpen) {
            setHamburgerMenuCssClasses([...hamburgerMenuCssClasses, classes.Open])
        } else {
            setHamburgerMenuCssClasses([classes.Hamburger])
        }

        setIsMenuOpen(!isMenuOpen);

        if(screenType === 'BIG_SCREEN') {
            setShowDropdown({ ...showDropdown, [tab.name]: !showDropdown?.[tab.name] })
        }
    }


    const toggleMenu = (event, tab) => {
        if (!isMenuOpen) {
            setHamburgerMenuCssClasses([...hamburgerMenuCssClasses, classes.Open])
        } else {
            setHamburgerMenuCssClasses([classes.Hamburger])
        }

        setIsMenuOpen(!isMenuOpen);

        if (screenType === 'SMALL_SCREEN') {
            setShowDropdown({ ...showDropdown, [tab.name]: !showDropdown?.[tab.name] })
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('load', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('load', handleWindowResize);
        };
    }, []);


    
    let currentLocation = useLocation();
    if (currentLocation.pathname.includes("products")
        || currentLocation.pathname.includes("privacy-policy")
        || currentLocation.pathname.includes("cookie-policy")) {
        navigationWrapperClasses.push(classes.ProductsPageNav);
    }

    const productCategories = getAllCategories().map(category => {
        let childRoutes = category.subCategories.map(subcategory => ({
            name: subcategory.name,
            path: "/products",
            state: {
                selectedCategoryConfig: {
                    categoryId: category.id,
                    firstSubCategoryId: subcategory.id,
                    secondSubCategoryId: null
                }
            }
        }));

        return {
            name: category.name,
            path: "/products",
            children: childRoutes,
            state: {
                selectedCategoryConfig: {
                    categoryId: category.id,
                    firstSubCategoryId: 1,
                    secondSubCategoryId: null
                }
            }
        }
    });
    
    
    const tabs = [ 
        { name: "Home", path: "/" },
        { name: "About Us", path: "/about-us" },
        { name: "Products", path: "/products", 
            children: productCategories,
            state: {
                selectedCategoryConfig: {
                    categoryId: 1,
                    firstSubCategoryId: 1,
                    secondSubCategoryId: null
                }
            }
        },
        { name: "Contact Us", path: "/contact-us" }
    ];

    const [showDropdown, setShowDropdown] = useState({});

    const navTabs = tabs.map(tab => 
                    <div key={tab.name}>
                        <NavLink 
                            onClick={(e) => toggleNavbar(e, tab)}
                            state={tab.state}
                            to={tab.path}
                            className={({ isActive }) =>
                            isActive ? clsx(classes.PrimaryNavTab, classes.NavTab) : classes.NavTab
                        }>
                            
                            {tab.name}

                            {tab?.children?.length > 0 
                                ? <FontAwesomeIcon 
                                        className={classes.DropdownIcon}
                                        onClick={() => setShowDropdown({ ...showDropdown, [tab.name]: !showDropdown?.[tab.name] })}
                                        icon={showDropdown?.[tab?.name] ? faAngleUp : faAngleDown } /> : undefined  }                            
                        </NavLink>
                        {screenType === 'BIG_SCREEN' && tab?.children?.length > 0 && showDropdown ? (
                            <div onMouseLeave={() => setShowDropdown({ ...showDropdown, [tab.name]: false })}
                                className={classes.Dropdown} style={{ display: showDropdown?.[tab?.name] ? 'flex' : 'none' }}>
                                {tab?.children.map(firstLevelDD =>
                                (<div key={firstLevelDD?.name}>
                                    <NavLink to={firstLevelDD?.path} className={classes.FirstLevelDDName} state={firstLevelDD.state}>
                                        {firstLevelDD?.name}
                                    </NavLink>
                                    {firstLevelDD?.children.map(secondLevelDD => {
                                        return <NavLink state={secondLevelDD.state} key={secondLevelDD?.name} to={secondLevelDD?.path} className={classes.SecondLevelDDName}>
                                            {secondLevelDD?.name}
                                        </NavLink>
                                    })}
                                </div>))}
                            </div> 
                        ) : undefined}

                        {screenType === 'SMALL_SCREEN' && tab?.children?.length > 0 && showDropdown ? (
                            <div onMouseLeave={() => setShowDropdown({ ...showDropdown, [tab.name]: false })}
                                className={classes.Dropdown} style={{ display: showDropdown?.[tab?.name] ? 'flex' : 'none' }}>
                                {tab?.children.map(firstLevelDD =>
                                (<div key={firstLevelDD?.name}>
                                    <NavLink onClick={(e) => toggleMenu(e, tab)} to={firstLevelDD?.path} className={classes.FirstLevelDDName} state={firstLevelDD.state}>
                                        {firstLevelDD?.name}
                                    </NavLink>
                                    {firstLevelDD?.children.map(secondLevelDD => {
                                        return <NavLink onClick={(e) => toggleMenu(e, tab)} state={secondLevelDD.state} key={secondLevelDD?.name} to={secondLevelDD?.path} className={classes.SecondLevelDDName}>
                                            {secondLevelDD?.name}
                                        </NavLink>
                                    })}
                                </div>))}
                                
                            </div> 
                        ) : undefined}
                        
                    </div>
                );

    return (
        <nav className={clsx(navigationWrapperClasses)}>
            <Logo />
            { screenType === 'BIG_SCREEN' ? <div className={classes.NavigationTabs}>{navTabs}</div> : undefined}
            { screenType === 'SMALL_SCREEN' && isMenuOpen ? <div className={classes.NavigationTabs}>
                {navTabs}
                <div className={classes.SmallScreenCtaButton} onClick={(e) => toggleMenu(e, {})} >
                    <Button redirectTo="/contact-us">Get in touch</Button>
                </div>
            </div> : undefined }
            <div className={classes.CtaButton}><Button redirectTo="/contact-us">Get in touch</Button></div>
            <div className={clsx(hamburgerMenuCssClasses)} onClick={() => toggleNavbar()} >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    );
}
export default NavigationTabs;