import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/free-mode";

import Layout from './hoc/Layout/Layout';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Product from './pages/Product/Product';
import ProductDetail from './pages/Product/ProductDetail/ProductDetail';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import TermsAndCondition from './pages/TermsAndCondition/TermsAndCondition';

class App extends Component {
  routes = [
    <Route key={'/'} path="/" element={<Home />} />, 
    <Route key={'/about-us'} path="/about-us" element={<About />} />,
    <Route key={'/contact-us'} path="/contact-us" element={<Contact />} />,
    <Route key={'/products'} path="/products" element={<Product />} />,
    <Route key={'/products/:id'} path="/products/:id" element={<ProductDetail />} />,
    <Route key={'/privacy-policy'} path="/privacy-policy" element={<PrivacyPolicy />} />,
    <Route key={'/cookie-policy'} path="/cookie-policy" element={<CookiePolicy />} />,
    <Route key={'/terms-and-conditions'} path="/terms-and-conditions" element={<TermsAndCondition />} />
  ];

  render() {
    return (
      <div className="App">
        <Layout>
          <Routes>
            {this.routes}
          </Routes>
        </Layout>
      </div>
    );
  }
}

export default App;