import data from '../assets/data/products.json';
import parameters from '../assets/data/parameters.json';


export const getAllProducts = () => {
    return data.products;
}

export const getAllCategories = () => {
    return data.categories;
}

export const getProductsByCategory = (categoryId, firstSubcategoryId, secondSubCategoryId) => {
    let filteredProducts = data.products;

    if(categoryId) {
        filteredProducts = filteredProducts.filter(product => product.categoryId === categoryId);
    }


    if (firstSubcategoryId) {
        filteredProducts = filteredProducts.filter(product => product.firstSubCategoryId === firstSubcategoryId);
    }


    if (secondSubCategoryId) {
        filteredProducts = filteredProducts.filter(product => product.secondSubCategoryId === secondSubCategoryId);
    }

    return filteredProducts;
}

export const getProductById = (id) => {
    const matchedProduct = data.products.filter(product => product.id === +id)
    let product = matchedProduct?.length === 1 ? matchedProduct[0] : null;

    if (product) {
        const params = parameters.parameters.filter(param => param.id === product.id);
        if(params?.length === 1) {
            product.parameters = params[0].data; 
        }
    }

    return product;
}

export const getCategoryById = (id) => {
    const matchedCatgegory = data.categories.filter(category => category.id === id)
    return matchedCatgegory?.length === 1 ? matchedCatgegory[0] : null;
}

export const getOtherCategories = (currentCategoryId) => {
    return data.categories.filter(category => category.id !== currentCategoryId);
}

export const getProductsCount = (categoryId, firstSubCategoryId) => {
    return data.products
                .filter(product => product.categoryId === categoryId)
                .filter(product => product.firstSubCategoryId === firstSubCategoryId)
                .length;
}