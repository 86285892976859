import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Navigation/Header/Header";

class Layout extends Component {
  render() {
    return (
      <Fragment>
          <Header />
          <main>
            {this.props.children}
          </main>
          <Footer/>
      </Fragment>
    );
  }
}

export default Layout;
